import { useState } from 'react';

import styled from 'styled-components';
import { Button, Input, PasswordInput } from 'components';
import router from 'routers/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import { authAPI } from 'services/apis';

import { ReactComponent as Logo } from 'assets/image/logo.svg';
import { SUCESS_STATUS } from 'constants/auth';
import { useLocales, useNotification } from 'hooks';

const BoxForm = styled.div`
    padding-top: 70px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`;
const LogoBody = styled.div``;
const Form = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    height: auto;
    margin-top: 20px;
    z-index: 1000;
`;

const BoxButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ButtonLogin = styled(Button)`
    margin-top: 30px;
    border-radius: 5px;
    color: #fafbfd;
    height: 41px;
    width: 121px;
    > span {
        font-size: 15px !important;
        font-weight: 400;
    }
`;
const Welcome = styled.span`
    padding: 0;
    font-size: 45px;
    font-family: 'Amaranth';
    margin-top: 20px;
    font-weight: 600;
    color: #0b141d;
`;
const Span = styled.span`
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;

    align-items: center;
    text-align: center;
    font-size: 33px;
    font-weight: 300;
    color: #0b141d;
    line-height: 1;
`;

const InputField = styled(Input)`
    width: 470px;
    height: 55px;
`;

export const Signin = () => {
    const [passwordToggle, setPasswordToggle] = useState<boolean>(true);
    const authLoading = useSelector<any>((state) => state.auth.loading === 'pending');
    const { error } = useNotification();
    const dispatch = useDispatch<any>();
    const { control, handleSubmit, formState } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });
    const { trans } = useLocales();

    const login = async (data: any) => {
        const { payload } = await dispatch(authAPI.signIn()({ ...data }));
        if (SUCESS_STATUS.includes(payload?.status)) {
            router.navigate('home', {}, { replace: true });
        } else {
            error({
                message: 'Invalid email or password',
            });
        }
    };

    return (
        <BoxForm>
            <Form>
                <LogoBody>
                    <Logo height="150px" width="197px" />
                </LogoBody>
                <Welcome>{trans('signin.welcome')}</Welcome>
                <Span>{trans('signin.login.title')}</Span>
                <form onSubmit={() => handleSubmit((data) => login(data))}>
                    <InputField
                        name="username"
                        defaultValue=""
                        label={trans('signin.email')}
                        type="email"
                        control={control}
                    />
                    <PasswordInput
                        name="password"
                        label={trans('signin.password')}
                        defaultValue=""
                        control={control}
                        setPasswordToggle={setPasswordToggle}
                        passwordToggle={passwordToggle}
                    />
                    <BoxButton>
                        <ButtonLogin
                            loading={Boolean(authLoading)}
                            disabled={!formState.isValid}
                            onClick={handleSubmit((data) => login(data))}
                        >
                            <span>{trans('signin.continue')}</span>
                        </ButtonLogin>
                    </BoxButton>
                </form>
            </Form>
        </BoxForm>
    );
};
