import { useEffect } from 'react';
import { useRouteNode } from 'react-router5';
import { pageStructure } from 'utils';
import styled from 'styled-components';
import { LockLoader } from 'pages/lockLoader/LockLoader';
import { Notification } from 'pages/notifcation';
import { useStopwatch } from 'react-timer-hook';
import { useDispatch } from 'react-redux';
import { authAPI } from 'services/apis';
import platform from 'platform';
import { useNotification } from 'hooks';

const AppBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

function App() {
    const { minutes } = useStopwatch({
        autoStart: true,
    });
    const { warning } = useNotification();

    const dispatch = useDispatch<any>();
    const { route } = useRouteNode('');
    const { Layout, Page } = pageStructure(route);

    useEffect(() => {
        if (minutes % 10 === 0) dispatch(authAPI.refresh()({}));
    }, [minutes]);
    const handleBrowser = () => {
        console.log('platform.name', platform.name);
        if (platform.name?.search('Chrome') == -1) {
            warning({
                message:
                    'Social Mind is compatible with Google Chrome & Chromium Engine browsers. For the best experience please use the platform with the recommended browsers',
            });
        }
    };
    useEffect(() => {
        handleBrowser();
    }, []);
    return (
        <AppBox>
            <Notification />
            <LockLoader />
            <Layout>
                <Page />
            </Layout>
        </AppBox>
    );
}

export default App;
