import { get } from 'lodash';
import { SUCESS_STATUS, PUBLIC_ROUTES } from 'constants/auth';
import { persistor, store } from 'app/store';

const authenticationMiddleware = () => (toState: any, fromState: any, done: any) => {
    const authAction = () => {
        const nextState = get(toState, 'name', null);

        const isPublicPage = PUBLIC_ROUTES.includes(nextState);
        let hasAccessToken = SUCESS_STATUS.includes(store.getState().auth.status);
        if (!isPublicPage && hasAccessToken) {
            done();
            return;
        }

        if (hasAccessToken) {
            if (nextState === 'home') {
                done();
                return;
            }

            done({
                redirect: {
                    name: 'home',
                    path: '/',
                    meta: { options: { replace: true }, redirected: true },
                },
            });
            return;
        }

        if (nextState === 'signin') {
            done();
            return;
        }
        done({
            redirect: {
                name: 'signin',
                path: '/signin',
                meta: { options: { replace: true }, redirected: true },
            },
        });
        return;
    };

    if (persistor.getState().bootstrapped) authAction();
    persistor.subscribe(authAction);
};

export default authenticationMiddleware;
