import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import router from 'routers/router';
import { authAPI } from 'services/apis';
import { useDispatch } from 'react-redux';
import { useLocales } from 'hooks';
import { Modal } from 'components';

interface HeaderProps {
    Logo?: any;
    children?: React.ReactNode;
    colorBackground?: String | undefined | any;
    logoStyle?: boolean;
    handleCloaseVideoPage?: Function;
    isRecording?: boolean;
}
import {
    BoadyDeleteModal,
    DeleteCardModal,
    TitleDeleteDetails,
    ReturnTextDelete,
    SureButton,
} from 'pages/dvr/style';

const Box = styled.div<HeaderProps>`
    margin: 0;
    display: flex;
    position: relative;
    width: 100%;
    max-width: 100% !important;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    ${(props) =>
        props?.colorBackground
            ? css`
                  background: ${props?.colorBackground};
              `
            : css`
                  background: transparent;
                  box-shadow: 0px 0px 15px rgb(222 229 253 / 25%);
              `}

    justify-content: space-between;
    max-height: 100px;
    @media screen and (min-width: 1199px) {
        /* start of large tablet styles */
        height: 100px;
    }
    @media screen and (min-width: 991px) and (max-width: 1199px) {
        /* start of medium tablet styles */
        height: 90px;
    }
`;

const LogoBox = styled.img`
    position: relative;
    height: 70px;
    width: 93px;
    margin-left: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
`;
const LogoWrap = styled.div<HeaderProps>`
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    /* padding: 0 30px; */
    ${(props) =>
        props?.logoStyle
            ? css`
                  padding: 0;
              `
            : css`
                  padding: 0 30px;
              `}
`;
interface ButtonLogoutProps {
    colorBackground: string;
}
const ButtonLogout = styled.button<ButtonLogoutProps>`
    margin-inline-end: 30px;
    border: 1px;
    box-shadow: none;
    font-size: 15px;
    ${(props) =>
        props?.colorBackground == '#1b202e'
            ? css`
                  background-color: #0b141d;
                  color: #55c7e7;
                  height: 60px;
                  width: 60px;
                  border-radius: 16px;
              `
            : css`
                  /* background-color: #0b141d; */
                  /* color: #55c7e7; */
                  height: 60px;
                  width: 60px;
                  border-radius: 16px;
                  color: #777f85;
                  /* background-color: #edf1fd; */
                  background-color: transparent;
              `}

    &:disabled {
        color: rgba(85, 199, 231, 0.2) !important;
        /* border: 2px solid rgba(85, 199, 231, 0.2) !important; */
        background: rgb(19 26 38) !important;

        cursor: not-allowed;
        > span:nth-child(1) {
            color: rgba(85, 199, 231, 0.2) !important;
        }
    }
`;
const HeaderTitle = styled.h4`
    font-weight: 400;
    font-size: 21px !important;
    line-height: 30px;
`;
const HeaderModal = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: #c9eef8;
    width: 100%;
    margin-top: 6px;
    padding-bottom: 5px;
`;
const LogoutBox = styled.div`
    display: flex;
    align-items: center;
`;
const TitleReturnToAdminModal = (
    <HeaderModal>
        <HeaderTitle>LogOut</HeaderTitle>
    </HeaderModal>
);
export function Header({
    Logo,
    children,
    colorBackground,
    logoStyle,
    handleCloaseVideoPage,
    isRecording,
}: HeaderProps) {
    const dispatch = useDispatch<any>();
    const { trans } = useLocales();
    const [logoutModalState, setLogoutModal] = useState<boolean>(false);
    const logout = () => {
        dispatch(authAPI.authSlice.actions.resetAction());
        router.navigate('signin');
    };
    const homeAction = () => {
        if (colorBackground === '#1b202e') {
            if (!handleCloaseVideoPage) return;
            handleCloaseVideoPage(true);
        } else {
            router.navigate('home');
        }
    };
    const logoutModal = () => {
        setLogoutModal(!logoutModalState);
    };
    return (
        <Box colorBackground={colorBackground}>
            <LogoWrap logoStyle={logoStyle}>
                <LogoBox src={Logo} alt="LOGO" onClick={homeAction} />
            </LogoWrap>
            {children}
            <LogoutBox>
                <ButtonLogout
                    onClick={colorBackground === '#1b202e' ? logoutModal : logout}
                    colorBackground={colorBackground}
                    disabled={isRecording}
                >
                    {trans('header.logout')}
                </ButtonLogout>
            </LogoutBox>
            <Modal
                isOpen={logoutModalState}
                setIsOpen={logoutModal}
                title={TitleReturnToAdminModal}
                Style={{ top: '19%', width: '550px', height: '331px', padding: '20px' }}
                flag={true}
            >
                <BoadyDeleteModal>
                    <DeleteCardModal>
                        <TitleDeleteDetails>
                            <ReturnTextDelete>Are you sure you want to logout ? </ReturnTextDelete>
                            <SureButton onClick={logout}>I’m sure</SureButton>
                        </TitleDeleteDetails>
                    </DeleteCardModal>
                </BoadyDeleteModal>
            </Modal>
        </Box>
    );
}
