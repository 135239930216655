import { default as axiosDefault } from 'axios';
import {
    withVersioning,
    VersioningStrategy,
    AxiosInstanceWithVersioning,
} from 'axios-api-versioning';

const BACKEND_API_ENDPOINT = 'https://staging.socialmindautism.com/reviewer/';

if (process.env.NODE_ENV === 'development' && !BACKEND_API_ENDPOINT) {
    console.log(
        'BACKEND_API_ENDPOINT config value not found. Please check your environment variables.',
    );
}

export const baseClient = axiosDefault.create({
    baseURL: `${BACKEND_API_ENDPOINT}`,
    withCredentials: true,
});

export const clientAction: AxiosInstanceWithVersioning = withVersioning(baseClient, {
    apiVersion: '',
    versioningStrategy: VersioningStrategy.UrlPath,
});

const axios = async ({ url, method, params = {}, ...rest }: any) => {
    const config = {
        url,
        method,
        data: {},
    };

    switch (method) {
        case 'POST':
        case 'PATCH':
        case 'PUT': {
            config.data = params;
            break;
        }

        default:
            break;
    }

    return await clientAction({
        ...config,
        ...rest,
    });
};

export default axios;
